import React from "react";

export default [
    {
        path: "/",
        name: "Login",
        component: React.lazy(() => import("./containers/Login/Login")),
        isPublic: true,
        exact: true
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: React.lazy(() => import("./containers/Dashboard/Dashboard")),
        isPublic: false,
        exact: true
    }
];
