import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import appActions from '../app/actions';
import GlobalAxios from '../../helpers/GlobalAxios';
import { API_ENDPOINTS } from '../../models/Constants';
import { toast } from 'react-toastify';
import AuthHelper from '../../helpers/AuthHelper';

export function* sagaMethods() {
	yield takeEvery(actions.LOGIN_REQUEST, function*(authData) {
		try {
			const response = yield call(GlobalAxios.post, API_ENDPOINTS.LOGIN_REQUEST, authData.data);
			if (response.data.success) {
				if (response.data.isDoctor) {
					toast.success('Başarıyla giriş yapıldı!');
					var profile = {
						tcNo: response.data.tcNo,
						ad: response.data.ad,
						soyad: response.data.soyad,
						yas: response.data.yas,
						cinsiyet: response.data.cinsiyet,
						isDoctor: response.data.isDoctor,
						girisZamani: new Date()
					};
					yield put({
						type: actions.LOGIN_SUCCESS,
						profile: profile,
						token: response.data.token
					});
					yield put(push('/dashboard'));
				} else {
					yield put({ type: actions.LOGIN_ERROR });
					toast.error("Bu TC'ye ait kişi doktor değil!");
				}
			} else {
				toast.error(response.data.message);
				yield put({ type: actions.LOGIN_ERROR });
				return;
			}
		} catch (e) {
			yield put({ type: actions.LOGIN_ERROR });
			toast.error(e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Giriş başarısız! Lütfen bilgileri tekrar deneyiniz!');
		}
	});
	yield takeEvery(actions.LOGIN_SUCCESS, function*(authData) {
		yield localStorage.setItem('profile', JSON.stringify(authData.profile));
		yield localStorage.setItem('jwtToken', authData.token);
		yield put(push('/'));
	});
	yield takeEvery(actions.LOGOUT, function*() {
		AuthHelper.clearProfile();
		AuthHelper.clearToken();
		yield put({
			type: appActions.REMOVE_STEP_1_TC_NO
		});
		yield put({
			type: appActions.GET_KPS_DETAILS_ERROR
		});
		yield put({
			type: appActions.SET_CURRENT_STEP,
			stepNo: 1
		});
		yield put(push('/'));
	});
	yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
		const token = AuthHelper.getToken();
		if (token) {
			if (!AuthHelper.isUserAuthenticated()) {
				toast.error('Your session has expired!');
				AuthHelper.clearToken();
				AuthHelper.clearProfile();
				yield put(push('/'));
			}
		}
	});
	yield takeEvery(actions.REGISTER_REQUEST, function*(formData) {
		try {
			yield call(GlobalAxios.post, API_ENDPOINTS.REGISTER, formData.data);
			yield put({
				type: actions.REGISTER_SUCCESS
			});
			toast.success('Registration is success!');
			yield put(push('/login'));
		} catch (e) {
			toast.error('Error!');
			yield put({ type: actions.REGISTER_ERROR });
		}
	});
	yield takeEvery(actions.REGISTER_SUCCESS, function*() {});
}

export default function* rootSaga() {
	yield all([fork(sagaMethods)]);
}
