import actions from './actions';
import appActions from '../app/actions';

const initState = { isLoading: false, token: null, profile: null, reCaptchaVisible: false };
export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_REQUEST:
			return { ...state, isLoading: true };
		case actions.LOGIN_SUCCESS:
			return { ...state, isLoading: false, profile: action.profile, reCaptchaVisible: false };
		case actions.LOGIN_ERROR:
			return { ...state, isLoading: false, reCaptchaVisible: true };
		case actions.REGISTER_REQUEST:
			return { ...state, isLoading: true };
		case actions.REGISTER_ERROR:
			return { ...state, isLoading: false };
		case actions.REGISTER_SUCCESS:
			return { ...state, isLoading: false };
		case appActions.GET_KPS_DETAILS:
			return { ...state, isLoading: false };
		case appActions.SET_CURRENT_STEP:
			return { ...state, isLoading: false };
		case appActions.INCREASE_CURRENT_STEP:
			return { ...state, isLoading: false };
		case actions.UPDATE_LOADING_STATE:
			return { ...state, isLoading: action.data };
		case actions.LOGOUT:
			return initState;
		default:
			return state;
	}
}
