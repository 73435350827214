import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import actions from './actions';
import GlobalAxios from '../../helpers/GlobalAxios';
import { API_ENDPOINTS } from '../../models/Constants';
import { toast } from 'react-toastify';
import AuthHelper from '../../helpers/AuthHelper';
export const getAppState = state => state.App;

export function* sagaMethods() {
	yield takeEvery(actions.GET_KPS_DETAILS, function*(actionData) {
		const data = {
			TcNo: actionData.tcNo
		};
		try {
			const response = yield call(GlobalAxios.post, API_ENDPOINTS.CHECK_KPS, data);
			if (response.data && response.data.success) {
				const fetchedKpsDetails = {
					ad: response.data.ad,
					soyad: response.data.soyad,
					yas: response.data.yas,
					cinsiyet: response.data.cinsiyet,
					tcNo: response.data.tcNo
				};
				toast.success('Hasta bilgileri başarıyla alındı!');
				yield put({
					type: actions.GET_KPS_DETAILS_SUCCESS,
					kpsDetails: fetchedKpsDetails
				});
				yield put({
					type: actions.SET_INPUT_VALUE,
					key: 'tcNo',
					value: actionData.tcNo
				});
				let previousDataArray;
				if (response.data.form) {
					var previousResult = response.data.form.testSonucu;
					previousDataArray = [
						{ key: 'kardiyoVaskuler', value: response.data.form.kardiyoVaskuler },
						{ key: 'dm', value: response.data.form.dm },
						{ key: 'ht', value: response.data.form.ht },
						{ key: 'kanser', value: response.data.form.kanser },
						{ key: 'digerImmunsupresif', value: response.data.form.digerImmunsupresif },
						{ key: 'kronikAkciger', value: response.data.form.kronikAkciger },
						{ key: 'akutOrganYetmezligi', value: response.data.form.akutOrganYetmezligi },
						{ key: 'akcigerAnormalBulgu', value: response.data.form.akcigerAnormalBulgu },
						{ key: 'pnomoniDurumu', value: response.data.form.pnomoniDurumu },
						{ key: 'pcr', value: response.data.form.pcr },
						{ key: 'ates', value: response.data.form.ates },
						{ key: 'oksuruk', value: response.data.form.oksuruk },
						{ key: 'solunumSikintisi', value: response.data.form.solunumSikintisi },
						{ key: 'sonOndortGunYurtdisi', value: response.data.form.sonOndortGunYurtdisi },
						{ key: 'yurtDisiTemasi', value: response.data.form.yurtDisiTemasi },
						{ key: 'sonOndortGunCovid19Temasi', value: response.data.form.sonOndortGunCovid19Temasi },
						{ key: 'burunAkintisi', value: response.data.form.burunAkintisi },
						{ key: 'infitrasyon', value: response.data.form.infitrasyon },
						{ key: 'testSonucu', value: response.data.form.testSonucu }
					];
					yield put({
						type: actions.UPDATE_INPUT_VALUE,
						key: previousDataArray,
						value: null
					});
					if (previousResult) {
						switch (previousResult) {
							case 'Yatış Yapıldı':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 11
								});
								break;

							case 'Vaka Yok':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 12
								});
								break;

							case 'Tetkik':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 4
								});
								break;

							case 'PCR Bekleniyor':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 6
								});
								break;

							case 'Covid19 Tedavisi':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 10
								});
								break;

							case '14 Gün İzleme':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 9
								});
								break;

							case 'Alternatif Tedavi':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 13
								});
								break;
							case 'Evde Takip':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 14
								});
								break;
							case 'Hastalık Bulguları':
								yield put({
									type: actions.SET_CURRENT_STEP,
									stepNo: 15
								});
								break;
							default:
								yield put({
									type: actions.INCREASE_CURRENT_STEP
								});
								break;
						}
					} else {
						yield put({
							type: actions.INCREASE_CURRENT_STEP
						});
					}
				} else {
					yield put({
						type: actions.UPDATE_INPUT_VALUE,
						key: 'testSonucu',
						value: null
					});
					yield put({
						type: actions.INCREASE_CURRENT_STEP
					});
				}
			} else {
				yield put({ type: actions.GET_KPS_DETAILS_ERROR });
				toast.error('Hasta bilgileri alınamadı! Lütfen tekrar deneyiniz!');
			}
		} catch (e) {
			yield put({ type: actions.GET_KPS_DETAILS_ERROR });
			toast.error(e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Hasta bilgileri alınamadı! Lütfen tekrar deneyiniz!');
		}
	});
	yield takeEvery(actions.SAVE_FORM_RESULT, function*(actionData) {
		let appState = yield select(getAppState);
		let profile = AuthHelper.getProfile();
		var kpsDetail = appState.kpsDetails;
		if (appState && appState.inputValues && appState.inputValues.length > 0) {
			let obj = {};
			appState.inputValues.map((value, index) => {
				obj[value.key] = value.value;
			});
			obj.yas = kpsDetail.yas;
			obj.tcNo = kpsDetail.tcNo;
			obj.cinsiyet = kpsDetail.cinsiyet;
			obj.doctorTcNo = profile.tcNo;
			try {
				const response = yield call(GlobalAxios.post, API_ENDPOINTS.FORM_RESULT, obj);
				if (response.data && response.data.success) {
					toast.success('Hasta bilgileri başarıyla kaydedildi!');
				} else {
					toast.error('Hasta bilgileri kaydedilemedi!');
				}
			} catch (e) {
				toast.error(e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'Form kaydedilemedi!');
			}
		}
	});
}

export default function* rootSaga() {
	yield all([fork(sagaMethods)]);
}
