import actions from './actions';
const initState = {
	currentMenu: null,
	currentStepNo: 1,
	inputValues: [],
	kpsDetails: null,
	step1TcNo: '',
	fetchError: false
};
export default function appReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_INPUT_VALUE:
			const valueArray = state.inputValues;
			if (typeof action.key === 'object') {
				//eğer object verilmişse objecti mevcut ile merge et.
				action.key.forEach(obj => {
					const elemIndex = valueArray.findIndex(x => x.key === obj.key);
					if (elemIndex > -1) {
						valueArray[elemIndex].key = obj.key;
						valueArray[elemIndex].value = obj.value;
					} else {
						valueArray.push({ key: obj.key, value: obj.value });
					}
				});
			} else {
				//eğer tekli değer verilmişse bu şekilde array elementini update et.
				const elemIndex = valueArray.findIndex(x => x.key === action.key);
				if (elemIndex > -1) {
					valueArray[elemIndex] = action.value;
				} else {
					valueArray.push({ key: action.key, value: action.value });
				}
			}
			return { ...state, inputValues: valueArray };
		case actions.UPDATE_INPUT_VALUE:
			let values = state.inputValues;
			if (typeof action.key === 'object') {
				action.key.map((value, index) => {
					const elemIndex = values.findIndex(x => x.key === value.key);
					if (elemIndex > -1) {
						values[elemIndex].key = value.key;
						values[elemIndex].value = value.value;
					} else {
						values.push({ key: value.key, value: value.value });
					}
				});
				//eğer object verilmişse objecti mevcut ile merge et.
			} else {
				if (values && values.length > 0) {
					// values.map((value, index) => {
					// 	if (value.key == action.key) {
					// 		value.value = action.value;
					// 	} else {
					// 		values.push({ key: action.key, value: action.value });
					// 	}
					// 	return value;
					// });

					const elemIndex = values.findIndex(x => x.key === action.key);
					if (elemIndex > -1) {
						values[elemIndex].key = action.key;
						values[elemIndex].value = action.value;
					} else {
						values.push({ key: action.key, value: action.value });
					}
				}
			}
			return { ...state, inputValues: values };
		case actions.GET_KPS_DETAILS_SUCCESS:
			return { ...state, kpsDetails: action.kpsDetails, fetchError: false };
		case actions.GET_KPS_DETAILS_ERROR:
			return { ...state, kpsDetails: null, inputValues: [], fetchError: true };
		case actions.GET_KPS_DETAILS:
			return { ...state, fetchError: false };
		case actions.SET_STEP_1_TC_NO:
			return { ...state, step1TcNo: action.step1TcNo };
		case actions.REMOVE_STEP_1_TC_NO:
			return { ...state, step1TcNo: '' };
		case actions.SET_CURRENT_STEP:
			return { ...state, currentStepNo: action.stepNo };
		case actions.INCREASE_CURRENT_STEP:
			return { ...state, currentStepNo: state.currentStepNo + 1 };
		case actions.CHANGE_CURRENT_MENU:
			return { ...state, currentMenu: action.currentMenu };
		case actions.RE_ANALYZE:
			return { ...state, inputValues: [], currentStepNo: 2 };
		default:
			return state;
	}
}
