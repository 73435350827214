const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	UPDATE_LOADING_STATE: 'UPDATE_LOADING_STATE',
	LOGIN_ERROR: 'LOGIN_ERROR',
	REGISTER_REQUEST: 'REGISTER_REQUEST',
	REGISTER_SUCCESS: 'REGISTER_SUCCESS',
	REGISTER_ERROR: 'REGISTER_ERROR',
	checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
	login: authData => ({
		type: actions.LOGIN_REQUEST,
		data: authData
	}),
	logout: () => ({
		type: actions.LOGOUT
	}),
	register: registerData => ({
		type: actions.REGISTER_REQUEST,
		data: registerData
	})
};
export default actions;
