import jwtDecode from 'jwt-decode';
class AuthHelper {
	getProfile = () => {
		const profile = localStorage.getItem('profile');
		if (profile) {
			return JSON.parse(profile);
		}
		return null;
	};
	setProfile = profile => {
		localStorage.setItem('profile', JSON.stringify(profile));
	};
	clearProfile = () => {
		localStorage.removeItem('profile');
	};

	clearToken = () => {
		localStorage.removeItem('jwtToken');
	};
	getToken() {
		const jwtToken = localStorage.getItem('jwtToken');
		if (jwtToken) {
			return jwtToken;
		}
		return null;
	}
	setToken = token => {
		localStorage.setItem('jwtToken', JSON.stringify(token));
	};
	isUserAuthenticated = () => {
		const token = this.getToken();
		if (token) {
			const decoded = jwtDecode(token);
			const currentTime = Date.now() / 1000;
			if (decoded.exp < currentTime) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	};
}
export default new AuthHelper();
