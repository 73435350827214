const actions = {
	CHANGE_CURRENT_MENU: 'CHANGE_CURRENT_MENU',
	SET_CURRENT_STEP: 'SET_CURRENT_STEP',
	INCREASE_CURRENT_STEP: 'INCREASE_CURRENT_STEP',
	SET_INPUT_VALUE: 'SET_INPUT_VALUE',
	UPDATE_INPUT_VALUE: 'UPDATE_INPUT_VALUE',
	SAVE_FORM_RESULT: 'SAVE_FORM_RESULT',
	GET_KPS_DETAILS: 'GET_KPS_DETAILS',
	GET_KPS_DETAILS_SUCCESS: 'GET_KPS_DETAILS_SUCCESS',
	GET_KPS_DETAILS_ERROR: 'GET_KPS_DETAILS_ERROR',
	SET_STEP_1_TC_NO: 'SET_STEP_1_TC_NO',
	REMOVE_STEP_1_TC_NO: 'REMOVE_STEP_1_TC_NO',
	RE_ANALYZE: 'RE_ANALYZE',
	saveFormResult: () => ({
		type: actions.SAVE_FORM_RESULT
	}),
	resetKpsDetails: () => ({
		type: actions.GET_KPS_DETAILS_ERROR
	}),
	getKpsDetails: tcNo => ({
		type: actions.GET_KPS_DETAILS,
		tcNo: tcNo
	}),
	reAnalyze: () => ({
		type: actions.RE_ANALYZE
	}),
	setInputValue: (key, value) => ({
		type: actions.SET_INPUT_VALUE,
		key: key,
		value: value
	}),
	updateInputValue: (key, value) => ({
		type: actions.UPDATE_INPUT_VALUE,
		key: key,
		value: value
	}),
	setCurrentStep: stepNo => ({
		type: actions.SET_CURRENT_STEP,
		stepNo: stepNo
	}),
	setStep1TcNo: step1TcNo => ({
		type: actions.SET_STEP_1_TC_NO,
		step1TcNo: step1TcNo
	}),
	removeStep1TcNo: () => ({
		type: actions.REMOVE_STEP_1_TC_NO
	}),
	changeCurrentMenu: currentMenu => ({
		type: actions.CHANGE_CURRENT_MENU,
		currentMenu: currentMenu
	})
};
export default actions;
